import React from 'react'
import Layout from '../components/layout/Layout'
import HighlightText from '../components/highlitedText'
import { useTranslation } from 'react-i18next';

function UnderDevelopment() {

    const { t } = useTranslation();

    return (
        <Layout>
            <section id="partners" className="sbr-section" style={{ paddingTop: '100px' }}>
                <div className="sbr-section-header" style={{ maxWidth: '960px' }}>
                    <HighlightText text={t('UnderDevelolpment.title')} startWordIndex={3} endWordIndex={4} />
                </div>
                <div className="under-development-container">
                    <div className="description">
                        <p>{t('UnderDevelolpment.description-top')}</p>
                        <p>{t('UnderDevelolpment.description-bottom')}</p>
                    </div>
                    <div className="subscribe-container">
                    <p>{t('UnderDevelolpment.subscribe-text')}</p>
                        <button className="subscribe-btn">{t('buttons.subscribe-to-newsletters')}</button>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default UnderDevelopment