import React from "react";
import { useTranslation } from 'react-i18next';

const About = () => {

    const { t } = useTranslation();
    var list = t('about-us.participiant', { returnObjects: true })

    const renderedExclusiveProjectsContent = list.map((item, index) => {
        return (
            <div className="about-item">
                    <div className="text">{item.position}</div>
                    <a href={item.link}>
                    <img src={require(`../../assets/image/participant/${item.logo}.svg`)} alt="" className="logo" />
                </a>
            </div>

        );
    });

    return (
        <section className="about-container">
            {t('about-us.text')}
            <div className="about-list">
                {renderedExclusiveProjectsContent}
            </div>
        </section>
    );
};

export default About;