// import React, { useState } from "react";
import SocialIItem from "./SocialItem";
import socialLinksData from "../../data/socialLinks.json"

const SocialLinks = () => {

    const renderedLinksContent = socialLinksData.map((item, index) => {

        return (
            <SocialIItem
                item={item}
                index={index}
            />
        );
    });

    return (
        <div className="social-container">
            <ul className="social-list">
                {renderedLinksContent}
            </ul>
        </div>
    );
};

export default SocialLinks;