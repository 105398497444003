import gsap from "gsap";
import {textAppear} from "../../../shared/transition";

export const tlEnter = (tweens) => {
    const tl = gsap.timeline({paused: true});

    tl.add(textAppear(tweens), 0);

    return tl;
}
