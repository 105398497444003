import React, { useEffect, useRef, useState } from 'react';
import { magicShow } from "./transition";
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import SocialLinks from '../../components/social/SocialLinks';
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";

const Contact = () => {
    const { t } = useTranslation();
    const [canSend, setCanSend] = useState(true);
    const [nameValue, setNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [messageValue, setMessageValue] = useState('');
    const form = useRef();

    const emailServer = process.env.REACT_APP_EMAIl_SERVER;
    const emailTemplate = process.env.REACT_APP_EMAIL_TEMPLATE;
    const emailKey = process.env.REACT_APP_EMAIL_KEY;

    const sendEmail = (e) => {
        e.preventDefault();
        if (canSend) {
            emailjs.sendForm(emailServer, emailTemplate, e.target, emailKey)
                .then((result) => {
                    toast.success('The message has been successfully sent!!')
                    setNameValue('')
                    setEmailValue('')
                    setPhoneValue('')
                    setMessageValue('')
                    console.log(result.text);
                    setCanSend(true);
                }, (error) => {
                    toast.error('The message was not sent, please try again later');
                    console.log(error.text);
                    setCanSend(true);
                });
        }
    };

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        magicShow(
            triggerRef.current,
            [headerRef.current, contentRef.current]
        );
    }, []);

    return (
        <section id="contact" className="sbr-section" style={{ paddingBottom: 50 }} ref={triggerRef}>
            <h1 className="sbr-section-header" ref={headerRef}>
            <HighlightText text={ t('contact.header') } startWordIndex={3} endWordIndex={5}/>
            </h1>
            <div className="sbr-contact-wrapper" ref={contentRef}>
                <div className="left-inform">
                { t('contact.left-inform') }
                </div>
                <div className='contact-form-wrap'>
                    <form ref={form} onSubmit={sendEmail}>

                        <div className="col-md-6">
                            <div className="form-grp">
                                <input id="name" style={{ WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none' }} type="text" name="from_name" value={nameValue} onChange={(e) => setNameValue(e.target.value)} placeholder={ t('contact.form.name') } required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-grp">
                                <input
                                    id="phone"
                                    type="phone"
                                    name="from_phone"
                                    inputmode="numeric"
                                    pattern="[0-9]*"
                                    value={phoneValue}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/\D/g, '');
                                        setPhoneValue(sanitizedValue);
                                    }}
                                    placeholder={ t('contact.form.phone') } />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-grp">
                                <input id='email' type="email" name="from_email" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder={ t('contact.form.email') } required />
                            </div>
                        </div>

                        <div className="form-grp">
                            <textarea id="message" name="message" value={messageValue} onChange={(e) => setMessageValue(e.target.value)} placeholder={ t('contact.form.message') } required></textarea>
                        </div>
                        <div className="submit-btn text-center">
                            <button type="submit" className="sbr-btn_cta">
                            { t('contact.form.submit-btn') }
                            </button>
                        </div>
                    </form>
                </div>
                <div className="right-inform">
                    <SocialLinks />
                    <div className="text-inform">
                    { t('contact.text-inform') }
                    </div>
                    <div className="contact-links">
                        <a href="tel:+971585773369" title="Позвонить на телефон">+971 58 577 3369</a>
                        <br />
                        <a href="mailto:contact@silentbroker.ae" title="Связаться через почту">contact@silentbroker.ae</a>
                        <br />
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Contact;