import React, { useState, useEffect } from 'react';

const ImageLoader = ({ src, alt, placeholder }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setLoading(false);
    };
    image.src = src;

    return () => {
      image.onload = null;
    };
  }, [src]);

  return (
    <div>
      {loading ? (
       placeholder
      ) : (
        <img src={src} alt={alt} />
      )}
    </div>
  );
};

export default ImageLoader;