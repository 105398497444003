import descriptIcon from "../../assets/image/icons/competencies/dubai.svg"
import { useTranslation } from 'react-i18next';

const Competencies = () => {

    const { t } = useTranslation();

    var list = t('competencies.list', { returnObjects: true })

    const renderedCompetenciesContent = list.map((item, index) => {

        return (
            <li>
                <div className="competencies-item-icon">
                    <img src={require(`../../assets/image/icons/competencies/${item.icon}.${item.icon === "globus" || item.icon === "mdi_business"? "png" : "svg"}`)} alt={item.text} />
                </div>
                <div className="competencies-item-text">{item.text}</div>
            </li>
        );
    });

    return (
        <section className="sbr-section">
            <div className="competencies-lable">
                <img src={descriptIcon} alt="" />
                <div className="decription">
                { t('competencies.text') }
                </div>
            </div>
            <div className="competencies-list-title">{ t('competencies.list-title') }</div>
            <ul className="competencies-list">
                {renderedCompetenciesContent}
            </ul>
        </section>
    );
};

export default Competencies;