import Layout from '../components/layout/Layout';
import Banner from '../components/banner/Banner';
import Accordion from '../sections/accordion/Accordion';
import CooperationSteps from '../sections/cooperation/CooperationSteps';
import Services from '../sections/services/Services';
import GetPresentation from '../sections/presentation/GetPresentation';
import Partners from '../sections/partners/Partners';
import Contact from '../sections/contact/Contact';
import SearchByType from '../sections/searchByType/SearchByType';
import Developers from '../sections/developers/Developers';
import PropertyLocations from '../sections/locations';
import NavigationBlock from '../sections/navigationBlock';


function Home() {

  return (
    <Layout>
      <Banner />
      <Accordion />
      <CooperationSteps />
      <Services />
      <GetPresentation />
      <Developers />
      <NavigationBlock/>
      <PropertyLocations />
      <SearchByType/>
      <Partners />
      <Contact />
    </Layout>
  );
}

export default Home;