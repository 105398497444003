import React, { useRef, useState, useEffect } from 'react';
import Layout from '../components/layout/Layout';
import HighlightText from '../components/highlitedText';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Developers() {
    const { t } = useTranslation();

    const firstGroupTitle = t('developers.categories.0.title', { returnObjects: true });
    const secondGroupTitle = t('developers.categories.1.title', { returnObjects: true });
    const thirdGroupTitle = t('developers.categories.2.title', { returnObjects: true });


    const firstGroup = t('developers.categories.0.data', { returnObjects: true });
    const secondGroup = t('developers.categories.1.data', { returnObjects: true });
    const thirdGroup = t('developers.categories.2.data', { returnObjects: true });

    const tabItems = [firstGroupTitle, secondGroupTitle, thirdGroupTitle];
    const [currentData, setCurrentData] = useState(firstGroup);

    
    

    const renderedDevelopers = () => {
        return (
            currentData.map((item, index) => (
                <Link to={`./${item.title.toLowerCase()}`} className="square-grid-item" style={{ backgroundImage: `url(${require(`../assets/image/developers/all-developers/${item.imageSrc}.jpg`)})`, backgroundSize: 'cover', backgroundPosition: 'center' }} key={index}>
                    <div className="developer-logo">
                        <img src={require(`../assets/image/developers/all-developers/logos/${item.logoSrc}.svg`)} alt="" />
                    </div>
                </Link>
            ))
        );
    };

    const btnLeft = useRef(null);
    const btnRight = useRef(null);
    const tabMenu = useRef(null);
    const [activeDrag, setActiveDrag] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
  
    useEffect(() => {
        const IconVisibility = () => {
            if (!tabMenu.current) return;
      
            const scrollLeftValue = Math.ceil(tabMenu.current.scrollLeft);
            const scrollableWidth = tabMenu.current.scrollWidth - tabMenu.current.clientWidth;
      
            btnLeft.current.style.display = scrollLeftValue > 0 ? "block" : "none";
            btnRight.current.style.display = scrollableWidth > scrollLeftValue ? "block" : "none";
          }
      
          const handleScroll = () => {
            IconVisibility();
          };
      
          tabMenu.current.addEventListener('scroll', handleScroll);

          document.addEventListener('mouseup', IconVisibility);
          IconVisibility();
  
      const handleRightClick = () => {
        tabMenu.current.scrollLeft += 150;
        setTimeout(IconVisibility, 50);
      };
  
      const handleLeftClick = () => {
        tabMenu.current.scrollLeft -= 150;
        setTimeout(IconVisibility, 50);
      };
  
      const handleResize = () => {
        IconVisibility();
      };
  
      btnRight.current.addEventListener('click', handleRightClick);
      btnLeft.current.addEventListener('click', handleLeftClick);
      window.addEventListener('resize', handleResize);
  
      // Initial call to setup visibility
      handleResize();
  
      // Dragging logic
      const handleMouseMove = (e) => {
        if (!activeDrag) return;
        tabMenu.current.scrollLeft -= e.movementX;
        IconVisibility();
      };
  
      const handleMouseUp = () => {
        setActiveDrag(false);
      };
  
      const handleMouseDown = () => {
        setActiveDrag(true);
      };
  
      tabMenu.current.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  
      // Cleanup
      return () => {
        if (btnRight.current) btnRight.current.removeEventListener('click', handleRightClick);
        if (btnLeft.current) btnLeft.current.removeEventListener('click', handleLeftClick);
        window.removeEventListener('resize', handleResize);
        if (tabMenu.current) {
            tabMenu.current.removeEventListener('mousedown', handleMouseDown);
            tabMenu.current.removeEventListener('scroll', handleScroll);
        }
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
    }, [activeDrag]);
  
    useEffect(() => {
      // Assuming tabs data might be fetched or dynamically generated
      // This is a placeholder to set tabs into state
      setTabs(document.querySelectorAll(".grid-content"));
    }, []);
  
    const handleTabClick = (index) => {
        setActiveTab(index);
        
        if (index === 0) {
          setCurrentData(firstGroup);
        } else if (index === 1) {
          setCurrentData(secondGroup);
        } else if (index === 2) {
            setCurrentData(thirdGroup);
        }
      };

    return (
        <Layout>
            <section id="developers" className="sbr-section" style={{ paddingTop: '100px' }}>
                <div className="sbr-section-header" style={{ maxWidth: '100%', textAlign: 'center' }}>
                    <HighlightText text={t('developers.header')} startWordIndex={1} endWordIndex={2} />
                </div>
                <div className="developers-page-main">
                    <div className="tab-nav-bar">
                    <div className="tab-navigation">
                        <i className="uil uil-angle-left left-btn" ref={btnLeft}></i>
                        <i className="uil uil-angle-right right-btn" ref={btnRight}></i>
                        <ul className="tab-menu" ref={tabMenu}>
                      {tabItems.map((city, index) => (
                          <li 
                              key={index} 
                              className={`tab-btn ${index === activeTab ? "active" : ""}`} 
                              onClick={() => handleTabClick(index)}
                          >
                              {city}
                          </li>
                      ))}
                  </ul>
                    </div>
                    </div>
                    <div className="grid-content">
                        {renderedDevelopers()}
                    </div>
                </div>
            </section>
        </Layout>
    )
}