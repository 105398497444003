import React, { useRef, useState } from 'react';
import Layout from '../../components/layout/Layout';
import { useTranslation } from 'react-i18next';
import HighlightText from '../../components/highlitedText';
import MetaPixel from '../../utils/meta/metaPixel';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import QuestionOneRadioGroup from './QuestionOne';
import QuestionFive from './QuestionFive';
import RadioButtonsGroup from './RadioButtonsGroup';
import { useNavigate } from 'react-router-dom';

function RealEstateQuiz() {
    const { t, i18n } = useTranslation();
    const [canSend, setCanSend] = useState(true);

    const emailServer = process.env.REACT_APP_EMAIl_SERVER;
    const emailTemplate = process.env.REACT_APP_EMAIL_TEMPLATE_QUIZ;
    const emailKey = process.env.REACT_APP_EMAIL_KEY;

    const [activeTab, setActiveTab] = useState(1); // Состояние для отслеживания активной вкладки

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex); // Обновление активной вкладки
    };

    const popupRef = useRef(null);

    const [propertyTypeValue, setPropertyType] = useState('');
    const [purchaseGoalValue, setPurchaseGoal] = useState('');
    const [propertyConditionValue, setPropertyCondition] = useState('');
    const [purchaseTimingValue, setPurchaseTiming] = useState('');
    const [messengerTypeValue, setMessengerType] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [contactPreferenceValue, setContactPreference] = useState('');


    const navigate = useNavigate();

    const sendForm = (e) => {
        e.preventDefault();
        if (canSend) {
            setCanSend(false);
            const formData = {
                property_type: propertyTypeValue,
                purchase_goal: purchaseGoalValue,
                property_condition: propertyConditionValue,
                purchase_timing: purchaseTimingValue,
                messenger_type: messengerTypeValue,
                from_name: nameValue,
                from_phone: phoneValue,
                contact_preference: contactPreferenceValue,
            };

            emailjs.send(emailServer, emailTemplate, formData, emailKey)
                .then((result) => {
                    // toast.success('The quiz has been successfully sent!!');
                    setPropertyType('');
                    setPurchaseGoal('');
                    setPropertyCondition('');
                    setPurchaseTiming('');
                    setMessengerType('');
                    setNameValue('');
                    setPhoneValue('');
                    setContactPreference('');
                    console.log(result.text);
                    setCanSend(true);
                    navigate('/thank-you');
                }, (error) => {
                    toast.error('The quiz was not sent, please try again later');
                    console.log(error.text);
                    setCanSend(true);
                });
        }
    };


    var messengersIcons = [
        {
            title: "WhatsApp",
            icon: "whatsapp"
        },
        {
            title: "Telegram",
            icon: "telegram"
        },
        {
            title: "Messenger",
            icon: "messenger"
        },
        {
            title: "Позвонить по телефону",
            icon: "phone"
        },
        {
            title: "Botim",
            icon: "botim"
        },
        {
            title: "Comera",
            icon: "comera"
        },
        {
            title: "Viber",
            icon: "viber"
        },
        {
            title: "WeChat",
            icon: "wechat"
        }
    ];

    var pageTitle = t('quiz.header')
    var subTitle = t('quiz.subheader')

    /* Question 1 */
    var questionOneTitle = t('quiz.questions.question-1.title')
    var questionOneAnswers = t('quiz.questions.question-1.answers', { returnObjects: true })

    /* Question 2 */
    var questionTwoTitle = t('quiz.questions.question-2.title')
    var questionTwoAnswers = t('quiz.questions.question-2.answers', { returnObjects: true })

    /* Question 3 */
    var questionThreeTitle = t('quiz.questions.question-3.title')
    var questionThreeAnswers = t('quiz.questions.question-3.answers', { returnObjects: true })

    /* Question 4 */
    var questionFourTitle = t('quiz.questions.question-4.title')
    var questionFourAnswers = t('quiz.questions.question-4.answers', { returnObjects: true })

    var questionEightTitle = t('quiz.questions.question-8.title')
    var questionEightAnswers = t('quiz.questions.question-8.answers', { returnObjects: true })

    return (
        <Layout>
            <MetaPixel />
            <section id="realEstateQuiz" className="sbr-section" style={{ paddingTop: '100px' }}>
                <div className="sbr-section-header" style={{ maxWidth: '100%', textAlign: 'center' }}>
                    <HighlightText text={pageTitle} startWordIndex={3} endWordIndex={15} />
                </div>
                <div className="quiz-container">
                    <h1 className="quiz-header">{subTitle}</h1>
                </div>
                <form className="quiz-form" onSubmit={(e) => { sendForm(e); }}>
                    <h2 className="title">{questionOneTitle}</h2>
                    <div class="grid-wrapper grid-col-auto">
                        <QuestionOneRadioGroup
                            items={questionOneAnswers}
                            name={"property-type"}
                            selectedValue={propertyTypeValue}
                            onChange={setPropertyType}
                        />
                    </div>

                    <h2 className="title">{questionTwoTitle}</h2>
                    <div className="radio-group">
                        <RadioButtonsGroup
                            items={questionTwoAnswers}
                            name={"purchase_goal"}
                            selectedValue={purchaseGoalValue}
                            onChange={setPurchaseGoal}
                        />
                    </div>

                    <h2 className="title">{questionThreeTitle}</h2>
                    <div className="radio-group">
                        <RadioButtonsGroup
                            items={questionThreeAnswers}
                            name={"property_condition"}
                            selectedValue={propertyConditionValue}
                            onChange={setPropertyCondition}
                        />
                    </div>

                    <h2 className="title">{questionFourTitle}</h2>
                    <div className="radio-group">
                        <RadioButtonsGroup
                            items={questionFourAnswers}
                            name={"purchase_timing"}
                            selectedValue={purchaseTimingValue}
                            onChange={setPurchaseTiming}
                        />
                    </div>

                    <h2 className="title">{t('quiz.questions.question-5.title')}</h2>

                    <div class="grid-wrapper grid-col-auto">
                        <QuestionFive
                            items={messengersIcons}
                            name={"messenger-type"}
                            selectedValue={messengerTypeValue}
                            onChange={setMessengerType}
                        />
                    </div>

                    <h2 className="title">{t('quiz.questions.question-6.title')}</h2>
                    <input id="userName" style={{ WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none', color: '#F2D4B5', fontSize: 'clamp(16px, 2vw + 1rem, 18px)' }} type="text" name="from_name" value={nameValue} onChange={(e) => setNameValue(e.target.value)} placeholder={t('quiz.questions.question-6.placeholder')} required />
                    <h2 className="title">{t('quiz.questions.question-7.title')}</h2>
                    <div className="form-grp">
                        <input
                            id="userPhone"
                            type="tel"
                            name="from_phone"
                            inputMode="numeric"
                            pattern="\+[0-9]*"
                            value={phoneValue}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, '');
                                if (!value.startsWith('+')) {
                                    value = '+' + value;
                                }
                                setPhoneValue(value);
                            }}
                            placeholder="+123456789"
                            style={{ color: '#F2D4B5', fontSize: 'clamp(16px, 2vw + 1rem, 18px)' }}
                        />
                    </div>

                    <h2 className="title">{questionEightTitle}</h2>
                    <div className="radio-group">
                        <RadioButtonsGroup
                            items={questionEightAnswers}
                            name={"contact_preference"}
                            selectedValue={contactPreferenceValue}
                            onChange={setContactPreference}
                        />
                    </div>
                    <br /><br />
                    <div className="submit-btn text-center">
                        <button type="submit" className="sbr-btn_cta">
                            {t('buttons.send')}
                        </button>
                    </div>
                </form>
            </section>

        </Layout>
    );
}

export default RealEstateQuiz;