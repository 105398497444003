import React from 'react'
import Layout from '../components/layout/Layout'

function NotFound() {
    return (
        <Layout>
            <section id="not-found" className="sbr-section" style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh', width: '100vw' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '70vh', width: '100vw', color: 'white' }}>
                    Page not found
                </div>
            </section>
        </Layout>
    )
}

export default NotFound