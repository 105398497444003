import React from 'react'
import Layout from '../components/layout/Layout'
import HighlightText from '../components/highlitedText'

function Partners() {

    const partnersItems = [
        {
            title: "Seven Century",
            imageSRC: "partners/images/seven-century",
            logoSRC: "partners/logo/seven-century",
            address: "Business Bay, Dubai, UAE",
            language: "English, Arabic",
            license: "License 602322",
            states: ["32 agents", "Real Estate Agency"],
            veryfied: true
        },
        {
            title: "Seven Century",
            imageSRC: "partners/images/seven-century",
            logoSRC: "partners/logo/seven-century",
            address: "Business Bay, Dubai, UAE",
            language: "English, Arabic",
            license: "License 602322",
            states: ["Verified Agency", "32 agents", "Real Estate Agency"],
            veryfied: true
        },
        {
            title: "Seven Century",
            imageSRC: "partners/images/seven-century",
            logoSRC: "partners/logo/seven-century",
            address: "Business Bay, Dubai, UAE",
            language: "English, Arabic",
            license: "License 602322",
            states: ["32 agents", "Real Estate Agency"],
            veryfied: true
        },
        {
            title: "Seven Century",
            imageSRC: "partners/images/seven-century",
            logoSRC: "partners/logo/seven-century",
            address: "Business Bay, Dubai, UAE",
            language: "English, Arabic",
            license: "License 602322",
            states: ["32 agents", "Real Estate Agency"],
            veryfied: true
        },
        {
            title: "Seven Century",
            imageSRC: "partners/images/seven-century",
            logoSRC: "partners/logo/seven-century",
            address: "Business Bay, Dubai, UAE",
            language: "English, Arabic",
            license: "License 602322",
            states: ["32 agents", "Real Estate Agency"],
            veryfied: true
        },
        {
            title: "Seven Century",
            imageSRC: "partners/images/seven-century",
            logoSRC: "partners/logo/seven-century",
            address: "Business Bay, Dubai, UAE",
            language: "English, Arabic",
            license: "License 602322",
            states: ["32 agents", "Real Estate Agency"],
            veryfied: true
        },
        {
            title: "Seven Century",
            imageSRC: "partners/images/seven-century",
            logoSRC: "partners/logo/seven-century",
            address: "Business Bay, Dubai, UAE",
            language: "English, Arabic",
            license: "License 602322",
            states: ["32 agents", "Real Estate Agency"],
            veryfied: true
        }
    ]

    const renderedPartners = partnersItems.map((item, index) => (
        <div className="card" key={index}>
            <img className="image" src={require(`../assets/image/${item.imageSRC}.png`)} alt="" />
            <div className="top-description">
                <div className="logo">
                    <img src={require(`../assets/image/${item.logoSRC}.svg`)} alt="" />
                </div>

                <div className="header">
                    <h2>{item.title}</h2>
                    <p>{item.address}</p>
                </div>
            </div>
            <div className="states-list">
                {item.states.map((state, index) => (
                    <p key={index}>{state}</p>
                ))}
            </div>
            <div className="bottom-description">
                <div className="languages">
                    <p>Top languages:</p>
                    <p>{item.language}</p>
                </div>
                <div className="license">
                    <p>{item.license}</p>
                </div>
            </div>
        </div>
    ));


    return (
        <Layout>
            <section id="partners" className="sbr-section" style={{ paddingTop: '100px' }}>
                <div className="sbr-section-header">
                    <HighlightText text="Наши Партнеры" startWordIndex={2} endWordIndex={2} />
                </div>
                <div className="our-partners-grid">
                    {renderedPartners}
                </div>
            </section>
        </Layout>
    )
}

export default Partners