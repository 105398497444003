import React from 'react'
import { useTranslation } from 'react-i18next';
import HighlightText from '../components/highlitedText'
import Layout from '../components/layout/Layout';

export default function HightPerformance() {

  const { t, i18n } = useTranslation();

  var listData = t('hight-performance.estates', { returnObjects: true });

  const renderedItems = listData.map((item, index) => (
    <div className="grid-item">
      <div className="item-header">
        <h2 className="title">{item.title}</h2>
        {item.status && <p className="delivery">{t('hight-performance.card.status')} {item.status}</p>}
      </div>
      <img src={require(`../assets/image/navigation-block/hight-performance/${item.imageSrc}.jpg`)} alt="" />
      <p>{item.description}</p>
      <div className="buttons">
        <a href="">{t('hight-performance.card.downloadButton')}</a>
        <a href={item.link} target="_blank" rel="noopener noreferrer">{t('hight-performance.card.visitWebsite')}</a>
      </div>
    </div>
  ));
  

  return (
    <Layout>
      <section id="investment-packages" className="sbr-section" style={{ paddingTop: '100px' }}>
        <div className="sbr-section-header" style={{ maxWidth: '100%' }}>
          <HighlightText text={t('hight-performance.header')} startWordIndex={2} endWordIndex={4} />
        </div>
        <div className="hight-performance-grid">
         { renderedItems }
        </div>
      </section>
    </Layout>
  )
}