import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import {magicShow, magicItemShow} from "./transition";
import HighlightText from '../../components/highlitedText';
import { Link } from 'react-router-dom';

function NavigationBlock() {

    const { t } = useTranslation();

        /* Animation effects */
        const triggerRef = useRef();
        const headerRef = useRef();
        const itemRef = useRef([]);
        itemRef.current = [];
    
        const combinedItemRefs = (el) => {
            if (el && !itemRef.current.includes(el)) {
                itemRef.current.push(el);
            }
        }
    
        useEffect(() => {
            magicItemShow(itemRef.current);
            magicShow(triggerRef.current, [headerRef.current]);
        }, []);

    var list = t('navigation-block.list', { returnObjects: true });

    return (
        <section className="sbr-section" ref={triggerRef}>
            <h1 className="sbr-section-header" ref={headerRef}>
                <HighlightText text={t('navigation-block.header')} startWordIndex={2} endWordIndex={2} />
            </h1>
            <ul className="navigation-block-grid">
                {list.map((item, index) => (
                   <li className="item" key={index} style={{ backgroundImage: `url(${require(`../../assets/image/navigation-block/${item.image}.png`)})` }} ref={combinedItemRefs}>
                        <h2>{item.title}</h2>
                        <Link to={item.link}>{t('navigation-block.learn-more')}</Link>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default NavigationBlock