import React from 'react'
import Layout from '../components/layout/Layout'
import { Link } from 'react-router-dom'
import ThankYouimage from '../assets/image/thank-you.svg'

function ThankYou() {


  return (
<Layout>
  <section 
    id="thank-you" 
    className="sbr-section" 
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '40px',
      alignItems: 'center',
      textAlign: 'center',
      height: '80vh',
      padding: '0',
    }}
  >
    <img src={ThankYouimage} style={{width: 'clamp(151px, 30vw, 250px);', height: 'auto', objectFit: 'scale-down', padding: '30px'}} alt=""/>
    <p style={{maxWidth: '420px', color: '#CA9575', fontSize: 'clamp(16px, 4vw, 22px)', padding: '0 20px'}}>Спасибо, Ваша заявка успешно обработана, мы свяжемся с вами в ближайшее время!</p>
    <Link to="/" style={{color: '#F2D4B5', fontSize: 'clamp(14px, 2vw, 18px)', padding: '0 20px'}}>вернуться на главную страницу</Link>
  </section>
</Layout>

  )
}

export default ThankYou
