import { useContext, useState, useRef, useEffect } from "react";
import { ButtonMenu } from '../buttons/ButtonMenu';
import { Link } from "react-router-dom";
import { LayoutContext } from "../context/context";
import { useLocation } from "react-router-dom";
import logo from "../../assets/image/logo.svg";
import { useTranslation } from 'react-i18next';

import useScrollListener from './hooks/useScrollListener'
import ButtonPhone from "../buttons/ButtonPhone";

const Navbar = () => {
    const { t, i18n } = useTranslation();

    const location = useLocation()
    const { scrollbar } = useContext(LayoutContext);
    const [isOpen, setIsOpen] = useState(false);
    const stripRef = useRef();

    const data = [
        {
            title: t('nav.about'),
            link: "/#about"
        },
        {
            title: t('nav.services'),
            link: "/#services"
        },
        {
            title: t('nav.real-estate'),
            link: "/#propertyTypes"
        },
        {
            title: t('nav.consultation'),
            link: "/#contact"
        },
        {
            title: t('nav.contact'),
            link: "/#contact"
        },
    ];

    const closeMenu = () => {
        setIsOpen(current => !current);
        document.body.classList.remove("no-scroll");
        scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
    };

    const handleClick = () => {
        setIsOpen(current => !current);

        if (!isOpen) {
            document.body.classList.add("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        } else {
            document.body.classList.remove("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        }
    };

    const [isLogoShown, setLogoShown] = useState(false);

    const navs = data.map((item, index) => {

        const isActive = location.hash === item.link;
        const onClickHandler = isActive ? () => setIsOpen(false) : closeMenu;

        if (index === 2) {
            return (
                <>
                    <Link className="sbr-navbar-nav" key={index} to={item.link} onClick={onClickHandler}>
                        <span>{item.title}</span>
                    </Link>
                    {/* {!isLogoShown && (
                      <a className="sbr-navbar-logo" href="/" aria-label="Home">
                          <img src={logo} style={{ width: '40px' }} alt="" />
                      </a>
                    )} */}
                </>
            );
        }

        return (
            <Link className="sbr-navbar-nav" key={index} to={item.link} onClick={onClickHandler}>
                <span>{item.title}</span>
            </Link>
        );
    });

    function showLogo() {
        if (window.innerWidth < 1025) {
            setLogoShown(true);
        } else {
            setLogoShown(false);
        }
    }

    useEffect(() => {
        showLogo()
        window.addEventListener('load', showLogo);
        window.addEventListener('resize', showLogo);
        return () => {
            window.removeEventListener('load', showLogo);
            window.removeEventListener('resize', showLogo);
        };
    });

    const logoStyle = !isLogoShown
      ? {}
      : {
          position: 'absolute',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '22px',
      };

      /* Hide nav when scroll down */ 
      
      const [navClassList, setNavClassList] = useState([]);
      const scroll = useScrollListener();
    
      useEffect(() => {
        const _classList = [];
    
        if (scroll.y > 750 && scroll.y - scroll.lastY > 0)
          _classList.push("-hidden");
    
        setNavClassList(_classList);
      }, [scroll.y, scroll.lastY]);

      const makeCall = () => {
        const phoneNumber = '+971585773369';
        window.location.href = `tel:${phoneNumber}`;
      };

    return (
        <nav className={`sbr-navbar ${isOpen ? "-open" : ""}`} >
            <div className="sbr-navbar-fill"></div>
            <div className={`sbr-navbar-strip ${navClassList.join(" ")}`} ref={stripRef}>
                <div className="sbr-navbar-container -lg">
                    <div className="sbr-navbar-grid">
                        <div
                        //   style={{
                        //       display: isLogoShown ? 'block' : 'none'
                        //   }}
                          className="sbr-navbar-grid-col -left"
                        >
                            {/* <div className="sbr-navbar-toggle">
                                <ButtonPhone onClick={makeCall} />
                            </div> */}
                            {/* {isLogoShown && ( */}
                              <a
                                className="sbr-navbar-logo"
                                href="/"
                                aria-label="Home"
                                style={{
                                    // height: '90px',
                                    width: '90px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                              >
                                  <img src={logo} alt=""/>
                              </a>
                            {/* )} */}
                        </div>
                        <div
                        //   style={logoStyle}
                          className="sbr-navbar-grid-col -center"
                        >
                            
                            <div className="sbr-navbar-navs" style={{ marginTop: '-6px' }}>
                                {navs}
                            </div>
                        </div>
                        <div className="sbr-navbar-grid-col -right">
                        <div className="sbr-navbar-toggle">
                                <ButtonMenu onClick={handleClick} />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
