import logo from "../../assets/image/logo.svg"
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <footer className="sbr-footer theme-bg">
            <div className="sbr-footer-content">
                <div className="sbr-footer-container">
                    {/* <div className="sbr-footer-row">
                        <Link className="sbr-footer-logo" to="/">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div> */}
                    <div className="sbr-footer-row">
                        <div className="sbr-footer-copyright"><span>Copyright © 2024, RSP All rights reserved.</span></div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
