import gsap from "gsap";

export const textAppear = (tween, options = {}) => {
    const tl = new gsap.timeline();

    tl.set(tween, {
        willChange: "transform"
    });

    tl.fromTo(tween, {
        y: 180,
        opacity: 0,
    }, {
        y: 0,
        opacity: 1,
        ease: "expo.out",
        duration: 3,
        stagger: options.stagger ?? 0.20,
        force3D: true
    }, 0);

    tl.set(tween, {
        willChange: "auto"
    });

    return tl;
};
