import { useState, useRef } from "react";
import SegmentedControl from "../../components/segmentControl"
import searchByLocations from "../../data/searchByLocations.json"
import map from "../../assets/locations-map.svg"
import ImageLoader from "../../components/imageLoader"
import Placeholder from "../../components/loader"
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";

const PropertyLocations = () => {

    const { t } = useTranslation();
    var citiesList = t('search-by-location.cities', { returnObjects: true });

    const [selectedValue1, setSelectedValue1] = useState("by location");

    const mapSegment = (
        <div className="map-frame">
            <ImageLoader src={map} alt="Description" placeholder={<Placeholder />} />
            {/* <img data-src={map} src={map} alt=""/> */}
        </div>
    );

    const renderedLocationsContent = searchByLocations.map((item, index) => (
        <div className="locations-item" key={index}>
            <img src={require(`../../assets/image/search-by-locations/${item.image}.jpg`)} alt="" />
            <h2>{item.title}</h2>
        </div>
    ));

    var formStyle = {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

    };

    var selectStyle = {
        maxWidth: '170px',
        background: 'transparent',
        color: '#F2D4B5',
        border: '1px solid #B97150',
        borderRadius: '10px',
        padding: '5px 20px'
    }

    const renderedCities = citiesList.map((item, index) => (
        <option value={item.title}>{item.title}</option>
    ));

    const locationsList = (
        <div className="locations-body">
            <div className="locations-list-header">
                <form style={formStyle}>
                    <label for="cities">{t('search-by-location.districts')}:</label>
                    <select id="cities" name="cities" onchange="handleCityChange()" style={selectStyle}>
                        {renderedCities}
                    </select>
                </form>
            </div>
            <div className="locations-list">
                {renderedLocationsContent}
            </div>
            <a href="/" className="show-more">
                {t('buttons.see-more')}
            </a>
        </div>
    );

    return (
        <section className="sbr-section">
            <h1 className="sbr-section-header">
                <HighlightText text={t('search-by-location.header')} startWordIndex={3} endWordIndex={5} />
            </h1>
            <div className="locatioins-container">
                <SegmentedControl
                    name="group-1"
                    callback={(val) => setSelectedValue1(val)}
                    controlRef={useRef()}
                    defaultIndex={0}
                    segments={[
                        {
                            label: t('search-by-location.segments.by-location'),
                            value: "by location",
                            ref: useRef(),
                        },
                        {
                            label: t('search-by-location.segments.by-map'),
                            value: "map",
                            ref: useRef(),
                        },
                    ]}
                />
            </div>
            {selectedValue1 === "by location" && locationsList}
            {selectedValue1 === "map" && mapSegment}
        </section>
    );
};

export default PropertyLocations;
