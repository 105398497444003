import {useEffect, useRef} from "react";
import {magicShow, magicItemShow} from "./transition";
import investmentPackagesData from "../../data/estateTypes.json"
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";

const SearchByType = () => {

    const { t, i18n } = useTranslation();

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];

    const combinedItemRefs = (el) => {
        if (el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        magicItemShow(itemRef.current);
        magicShow(triggerRef.current, [headerRef.current]);
    }, []);

    var list = t('search-by-type.list', { returnObjects: true })
    const renderedExclusiveProjectsContent = list.map((item, index) => {

        return (
            <div className="search-by-type-item" ref={combinedItemRefs} key={index}>
                <div className="item-image">
                    <img src={require(`../../assets/image/estateByType/${item.image}.svg`)} alt="" />
                    <div className="title">{item.text}</div>
                </div>
            </div>
        );
    });

    return (
        <section id="propertyTypes" className="sbr-section" ref={triggerRef}>
            <h1 className="sbr-section-header" ref={headerRef}>
            <HighlightText text={ t('search-by-type.header') } startWordIndex={2} endWordIndex={4}/>
            </h1>
            <div className="search-by-type-list">
                {renderedExclusiveProjectsContent}
            </div>
        </section>
    );
};

export default SearchByType;