import { useEffect, useContext, useRef } from "react";
import { LayoutContext } from "../context/context";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { tlEnter } from "./transitions";
import profileImage from "../../assets/image/broker-img.jpg"
import VideoEmbedded from "../../components/videoEmbedded";


const Banner = () => {
    const { t } = useTranslation();

    const { isLoading } = useContext(LayoutContext);
    const textRef = useRef();
    const informRef = useRef();

    useEffect(() => {
        if (!isLoading) {
            tlEnter([informRef.current, textRef.current]).play();
        }
    }, [isLoading]);

    return (
        <header className="sbr-hero">
            <div className="sbr-hero-content -cb">
                <div className="sbr-hero-container">
                    <div className="sbr-hero-main">
                        <div className="sbr-hero-video">
                            <VideoEmbedded
                                src={require(`../../assets/media/video.mp4`)}
                                srcMobile={require(`../../assets/media/video.mp4`)}
                            />
                        </div>
                        <div className="sbr-hero-body">
                            <div className="sbr-hero-left-content" ref={informRef}>
                                <div className="sbr-profile">
                                    <img src={profileImage} alt="" />
                                </div>
                                <div className="left-content-text" style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="sbr-hero-text">
                                    <p>{t('banner.name')}</p>
                                </div>
                                <div className="sbr-hero-header">
                                    <p>{t('banner.position')}</p>
                                </div>
                                </div>
                                
                            </div>
                            <div className="sbr-hero-right-content" ref={textRef}>
                                <div className="title">{t('banner.description')}</div>
                                <div className="subtitle" style={{ fontSize: '10px' }}>ROCK SOLID PROPERTIES ©</div>
                                <Link className="sbr-rounded-button" to="/quiz">{t('banner.buttons.downloadCatalog')}</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
}

export default Banner;