import React from "react";
import CloseIcon from "../../assets/image/x-mark.svg"
function Popup(props) {
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-container">
                {props.children}
            </div>
            <div className="popup-close-btn" onClick={() => props.setTrigger(false)}><img src={CloseIcon} alt="close" /></div>
        </div>
    ) : "";
}

export default Popup