import { useEffect, useRef } from "react";
import { magicShow } from "./transition";
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";
import { Link } from "react-router-dom";

const Developers = () => {

    const { t } = useTranslation();

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const contentRef = useRef([]);

    useEffect(() => {
        magicShow(triggerRef.current, [headerRef.current, contentRef.current]);
    }, []);

    var list = t('developers-by-levels.list', { returnObjects: true })
    const renderedDevelopersContent = list.map((item, index) => {

        return (
            <>
                {item.developers.map((developer, index) => (
                    <li key={index}>
                        <img src={require(`../../assets/image/developers/${developer.logo}.svg`)} alt="" />
                    </li>
                ))}
            </>
        );
    });


    return (
        <section className="sbr-section" ref={triggerRef}>
            <h1 className="sbr-section-header" ref={headerRef}>
                <HighlightText text={t('developers-by-levels.header')} startWordIndex={2} endWordIndex={3} />
            </h1>
            <div className="developer-level-container" ref={contentRef}>
                <ul className="developers-levels-list">
                    {renderedDevelopersContent}
                </ul>
                <Link className="sbr-rounded-button" to="/developers">{t('buttons.more')}</Link>
            </div>
        </section>
    );
};

export default Developers;