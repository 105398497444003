import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import i18n from './i18n'
import '../src/assets/styles/amocrm.scss'
import './assets/styles/main.scss';
import "./firebase/firebaseConfig";
import Home from './pages/Home';
import { I18nextProvider } from 'react-i18next';
import Quiz from "./pages/quiz/Quiz";
import Developers from "./pages/Developers";
import Developer from "./pages/Developer";
import Partners from "./pages/Partners";
import ExclusiveProjects from "./pages/ExclusiveProjects";
import HightPerformance from "./pages/HightPerformance";
import CatalogsByPreference from "./pages/CatalogsByPreference";
import CatalogsByClass from "./pages/CatalogsByClass";
import InvestmentPackages from "./pages/InvestmentPackages";
import PremiumLocations from "./pages/PremiumLocations";
import ThankYou from "./pages/ThankYou";

function App() {

  const auth = getAuth();
  const [currentUserUID, setCurrentUserUID] = useState(null);

  /* Auth listener */
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setCurrentUserUID(uid);
    } else {
      // User is signed out
    }
  });

  return (

    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/developers/:developerId" element={<Developer />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/exclusive-projects" element={<ExclusiveProjects />} />
        <Route path="/hight-performance-real-estate" element={<HightPerformance />} />
        <Route path="/catalogs-by-preference" element={<CatalogsByPreference />} />
        <Route path="/catalogs-by-class" element={<CatalogsByClass />} />
        <Route path="/investment-packages" element={<InvestmentPackages />} />
        <Route path="/premium-locations" element={<PremiumLocations />} />
      </Routes>
    </I18nextProvider>

  );
}

export default App;
