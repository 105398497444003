import { useEffect, useRef } from "react";
import { magicShow, magicItemShow } from "./transition";
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";

const CooperationSteps = () => {

    const { t } = useTranslation();
    var stages = t('stages-of-cooperation.stages-list', { returnObjects: true })

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];

    const combinedItemRefs = (el) => {
        if (el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        magicItemShow(itemRef.current);
        magicShow(triggerRef.current, [headerRef.current]);
    }, []);

    const items = stages.map((item, index) => {

        return (
            <div className="step-item-container" ref={combinedItemRefs} key={index}>
                <div className="step-top">
                    <div className="step-count">{index < 9 ? `0${index + 1}` : index + 1}</div>
                    <div className="step-icon">
                        <img src={require(`../../assets/image/icons/${item.icon}.svg`)} alt={item.text} />
                    </div>
                </div>
                <div className="step-item-content">
                    <HighlightText text={item.text} startWordIndex={index === 0 ? 12: (index === 1 ? 2 : 6)} endWordIndex={index === 0 ? 14: (index === 1 ? 3 : 10)}/>
                </div>
            </div>
        );
    });

    return (
        <>
        <section className="sbr-section" ref={triggerRef}>
            <div className="sbr-section-header" ref={headerRef}>
            <HighlightText text={ t('stages-of-cooperation.header') } startWordIndex={2} endWordIndex={3}/>
            </div>
            <div className="cooperation-list">
                {items}
            </div>
        </section>
        </>
    );
};

export default CooperationSteps;