import React from "react";

const SocialItem = ({
    item,
    index,
}) => (
    <li>
        <div className="social-item">
            <a href={item.url}>
            <img src={require(`../../assets/image/icons/social/${item.icon}.svg`)} alt={item} className="social-icon" style={index === 0 ? { padding: '0', border: '0' } : {}}/>
            </a>
        </div>
    </li>
);

export default SocialItem;