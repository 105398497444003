import React from 'react';

function QuestionOneRadioGroup({ items, name, selectedValue, onChange }) {
  return items.map((item, index) => (
    <label for={`${name}-${item.id}`} className="radio-card" key={index}>
      <input 
        id={`${name}-${item.id}`} 
        name={name}
        type="radio"
        value={item.title}
        checked={selectedValue === item.title}
        onChange={(e) => onChange(e.target.value)}
        required
      />
      <div className="card-content-wrapper" style={{ backgroundImage: `url(${require(`../../assets/image/quiz/${item.image}.jpg`)})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <span className="check-icon"></span>
        <div className="card-content">
          <h4>{item.title}</h4>
        </div>
      </div>
    </label>
  ));
}

export default QuestionOneRadioGroup;

