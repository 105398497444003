import React from 'react'
import HighlightText from '../components/highlitedText'
import Layout from '../components/layout/Layout'
import { useTranslation } from 'react-i18next';

export default function InvestmentPackages() {

  const { t, i18n } = useTranslation();

  var listData = t('investment-packages.packages', { returnObjects: true });

  const renderedItems = listData.map((item, index) => (

    <div className="grid-item">
      <div className="image">
        <img src={require(`../assets/image/navigation-block/investment-projects/${item.imageSrc}.jpeg`)} alt="" />
      </div>
      <div className="description">
        <h2>{item.title}</h2>
        <a href={item.downloadURL}>{t('investment-packages.downloadButton')}</a>
      </div>
    </div>
  ))

  return (
    <Layout>
      <section id="investment-packages" className="sbr-section" style={{ paddingTop: '100px' }}>
        <div className="sbr-section-header" style={{ maxWidth: '100%' }}>
          <HighlightText text={t('investment-packages.header')} startWordIndex={2} endWordIndex={2} />
        </div>
        <div className="investment-packages-grid">
          {renderedItems}
        </div>
      </section>
    </Layout>
  )
}