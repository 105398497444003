


import React from "react";
import Layout from "../components/layout/Layout";
import HighlightText from "../components/highlitedText";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NotFound from "./NotFound";

export default function Developer() {
    const { developerId } = useParams();

    const history = useNavigate();

    const developersData = {
        meraas: {
            name: "MERAAS",
            bannerImage: "meraas-banner.jpg",
            bannerDescription: "Район, расположенный в Deira - группа из пяти островов Marina, Central, Shore, Golf и Elite общей площадью 17 кв км. Проект разрабатывается компанией Nakheel.",
            logo: "meraas.svg",
            about: "В 2020 году Meraas стал частью крупного государственного инвестиционного холдинга Dubai Holding, получив статус государственной компании. В переводе с арабского meraas означает «наследие».",
            projects: [
                { title: "Thyme Central Park", image: "meraas/projects/thyme-central-park.jpg" },
                { title: "Dubai Wharf", image: "meraas/projects/dubai-wharf.jpg" },
                { title: "Design Quarter", image: "meraas/projects/design-quarter.jpg" },
                { title: "Jomana MJL", image: "meraas/projects/jomana-mjl.jpg" },
                { title: "Erin Central Park", image: "meraas/projects/erin-central-park.jpg" },
                { title: "Bluewaters Bay", image: "meraas/projects/bluewaters-bay.jpg" },
                { title: "Fern Central Park", image: "meraas/projects/fern-central-park.jpg" },
                { title: "Central Park Laurel", image: "meraas/projects/central-park-laurel.jpg" },
            ],
            info: {
                founded: "2007",
                city: "Dubai",
                employees: "5000",
                capital: "1.4 млрд $"
            },
            portfolio: [
                {"гектаров жилой и коммерческой недвижимости": "700"},
                {"отелей": "8"},
                {"развлекательных центров": "11"}
            ]
        
        
        },
    };

    if (!developersData[developerId]) {
        return <NotFound />;
        // return <div>Page not found</div>;
    }


    var contactUsItems = [
        {
            title: "+971 58 577 3369",
            icon: "phone",
            link: ""
        },
        {
            title: "Info@rsp.expert",
            icon: "email",
            link: "mailto:info@rsp.expert"
        },
        {
            title: "silentbroker.ae",
            icon: "browser",
            link: "https://silentbroker.ae"
        },
    ];

    const renderedProjects = () => {
        const projects = developersData[developerId].projects;

        return (
            projects.map((item, index) => (
                <div className="project-grid-item" style={{ backgroundImage: `url(${require(`../assets/image/developers/all-developers/${item.image}`)})`, backgroundSize: 'cover', backgroundPosition: 'center' }} key={index}>
                    <p>{item.title}</p>
                </div>
            ))
        );
    };

    const ContactUsItems = () => {

        const icons = [, ,]
        return (
            contactUsItems.map((item, index) => (
                <div className="contact-us-item" key={index}>
                    <label>
                        <img src={require(`../assets/image/icons/contact/${item.icon}.svg`)} alt="иконка" className="icon" />
                        <p>{item.title}</p>
                    </label>
                </div>
            ))
        );
    };

    const developerInfo = developersData[developerId].info;

    return (
        <Layout>
            <section id="developer-info-header" className="sbr-section" style={{ paddingTop: '100px', paddingBottom: '50px' }}>
                <div className="developer-banner">
                    <img src={require(`../assets/image/developers/all-developers/info/${developersData[developerId].bannerImage}`)} alt="banner" />
                    <div className="developer-header">
                        <h1>{developersData[developerId].name}</h1>
                        <p>{developersData[developerId].bannerDescription}</p>
                    </div>
                </div>
            </section>


           

            <section id="developer-about" className="sbr-section">
                <div className="sbr-section-header" style={{ maxWidth: '100%', textAlign: 'left' }}>
                    <HighlightText text="Информация о застройщике" startWordIndex={2} endWordIndex={3} />
                </div>
                <div className="developer-basic-info">
                    <div className="logo item">
                        <img src={require(`../assets/image/developers/all-developers/logos/${developersData[developerId].logo}`)} alt="" />
                    </div>
                   <div className="item">
                <div className="title">Год основания</div>
                <div className="description">{developerInfo.founded}</div>
            </div>
            <div className="item">
                <div className="title">Главный офис</div>
                <div className="description">{developerInfo.city}</div>
            </div>
            <div className="item">
                <div className="title">Сотрудников</div>
                <div className="description">{developerInfo.employees}</div>
            </div>
            <div className="item">
                <div className="title">Оценка</div>
                <div className="description">{developerInfo.capital}</div>
            </div>
                </div>

                <div className="developer-detail-container">
                    <div className="image">
                        <img src={require(`../assets/image/developers/all-developers/info/${developersData[developerId].bannerImage}`)} alt="" />
                    </div>
                    <div className="description-box">
                         <p>{developersData[developerId].about}</p>
                         <p>Масштабное портфолио застройщика включает:</p>
                         <div className="count-box">
                                {developersData[developerId].portfolio.map((item, index) => (
                         <div className="count-item" style={{ color: 'white' }} key={index}>
                <p>{Object.values(item)[0]}</p>
                <p>{Object.keys(item)[0]}</p>
            </div>
        ))}
    </div>
</div>
                </div>
            </section>

            <section id="developer-projects" className="sbr-section">
                <div className="sbr-section-header" style={{ maxWidth: '100%', textAlign: 'left' }}>
                    <HighlightText text="Проекты застройщика" startWordIndex={2} endWordIndex={2} />
                </div>
                <div className="developer-projects-grid">
                    {renderedProjects()}
                </div>
            </section>

            <section id="contact-us" className="sbr-section">
                <h1 className="contact-us-subtitle">Лучшее агентство недвижимости</h1>
                <div className="sbr-section-header" style={{ maxWidth: '100%', textAlign: 'left' }}>
                    <HighlightText text="Свяжитесь с нами" startWordIndex={2} endWordIndex={3} />
                </div>
                <div className="contact-us">
                    {ContactUsItems()}
                </div>
            </section>
        </Layout>
    );
};
