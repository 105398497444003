import React from 'react';

function QuestionFive({ items, name, selectedValue, onChange }) {
  return items.map((item, index) => (
    <label htmlFor={`${name}-${item.title}`} className="radio-card" key={index}>
      <input 
        type="radio" 
        name={name}
        id={`${name}-${item.title}`} 
        value={item.title} 
        checked={selectedValue === item.title}
        onChange={(e) => onChange(e.target.value)}
        required
      />
      <div className="card-content-wrapper">
        <span className="check-icon"></span>
        <div className="card-content">
          <img
            src={require(`../../assets/image/quiz/messengers/${item.icon}.svg`)}
            alt=""
          />
          <h4>{item.title}</h4>
        </div>
      </div>
    </label>
  ));
}

export default QuestionFive;
