import React from 'react';

export default function RadioButtonsGroup({ items, name, selectedValue, onChange }) {
  return (
    <div>
      {items.map((item, index) => (
        <div className="radio" key={index}>
          <input
            id={item.id}
            name={name}
            type="radio"
            value={item.title}
            checked={selectedValue === item.title}
            onChange={(e) => onChange(e.target.value)}
            required
          />
          <label htmlFor={item.id} className="radio-label">{item.title}</label>
        </div>
      ))}
    </div>
  );
}
