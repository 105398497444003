import React from 'react'
import Layout from '../components/layout/Layout'
import { useTranslation } from 'react-i18next';
import HighlightText from '../components/highlitedText'

export default function ExclusiveProjects() {

  const { t, i18n } = useTranslation();

  var listData = t('exclusive-projects.projects', { returnObjects: true });

  const renderedItems = listData.map((item, index) => (

    <div className="projects-card">
      <div className="image-box" style={{ backgroundImage: `url(${require(`../assets/image/navigation-block/exclusive-projects/${item.imageSRC}.jpg`)})` }}>
        <img src={require(`../assets/image/participant/${item.logo}.svg`)} alt="" />
        <h2>{item.title}</h2>
      </div>
      <div className="description-box">
        <div className="text">{item.description}</div>
        <div className="info">
          <div className="year">
            <p>{t('exclusive-projects.card.founded')}</p>
            <p>{item.founded}</p>
          </div>
          <div className="location">
            <p>{t('exclusive-projects.card.location')}</p>
            <p>{item.city}</p>
          </div>
        </div>
        <a href="">{t('exclusive-projects.card.button')}</a>
      </div>
    </div>
  ))

  return (
    <Layout>
      <section id="exclusive-projects" className="sbr-section" style={{ paddingTop: '100px' }}>
        <div className="sbr-section-header" style={{ maxWidth: '100%' }}>
          <HighlightText text={t('exclusive-projects.header')} startWordIndex={2} endWordIndex={4} />
        </div>
        <div className="exclusive-projects-list">
          {renderedItems}
        </div>
      </section>
    </Layout>
  )
}