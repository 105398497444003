import React from 'react';

function HighlightText({ text, startWordIndex, endWordIndex }) {
  // Разделяем текст на слова
  const words = text.split(' ');

  // Создаем новый массив слов с выделенным тегом
  const highlightedWords = words.map((word, index) => {
    const isWithinRange = index + 1 >= startWordIndex && index + 1 <= endWordIndex;

    if (isWithinRange) {
      return <span key={index} className="highlighted">{word}</span>;
    } else {
      return word;
    }
  });

  // Возвращаем JSX с выделенными словами
  return (
    <>
      {highlightedWords.map((word, index) => (
        // Используем index в качестве ключа, так как мы возвращаем массив элементов
        <React.Fragment key={index}>
          {index > 0 && ' '} {/* Добавляем пробел между словами, кроме первого */}
          {word}
        </React.Fragment>
      ))}
    </>
  );
}

export default HighlightText;
