import React, { useEffect } from "react";
import About from '../about/About';
import Competencies from '../competencies/Competencies';
import { useTranslation } from 'react-i18next';

const Accordion = () => {

    const { t } = useTranslation();

    const accordionAboutData = [
        {
          header: t('about-us.title'),
          content: <About/>,
        },
        {
          header: t('competencies.title'),
          content: <Competencies/>,
        }
      ];


function accordion(target) {
    const header = target.classList;
    const content = target.nextElementSibling.classList;
   
    if (header.contains('accordion__header--active')) {
        header.remove('accordion__header--active');
        content.remove('accordion__content--show');
    } else {
        header.add('accordion__header--active');
        content.add('accordion__content--show');
    }
}

    useEffect(() => {
        const accordionHeaders = document.querySelectorAll('accordion__header');

        for (let i = 0; i < accordionHeaders.length; i++) {
            let accordionHeader = accordionHeaders[i];
            accordionHeader.addEventListener('click', () => {
                accordion(accordionHeader);
            });
        }

        return () => {
            for (let i = 0; i < accordionHeaders.length; i++) {
                let accordionHeader = accordionHeaders[i];
                accordionHeader.removeEventListener('click', () => {
                    accordion(accordionHeader);
                });
            }
        };
    }, []);

    return (
        <section className="sbr-section">
            <div className="accordion">
                {accordionAboutData.map((item, index) => (
                    <div key={index} className="accordion__item">
                        <div className="accordion__header" onClick={(e) => accordion(e.currentTarget)}>
                            <h2 className="accordion__header-title">{item.header}</h2>
                            <div className="toggle-button">
                            <i className="accordion__header-toggle"></i>
                            </div>
                           
                        </div>
                        <div className="accordion__content">
                            {item.content}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Accordion;
