import { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import ServiceItem from "./ServiceItem";
import { magicShow } from "./transition";
import HighlightText from "../../components/highlitedText";

const Services = () => {

    const { t } = useTranslation();

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const captionRef = useRef();
    const listRef = useRef();

    useEffect(() => {
        magicShow(
            triggerRef.current,
            [headerRef.current, captionRef.current, listRef.current]
        );
    }, []);

    var services = t('services.services-list', { returnObjects: true })
    const renderedServicesContent = services.map((item, index) => {

        return (
            <ServiceItem
                item={item}
                index={index}
            />
        );
    });

    return (
        <section id="services" className="sbr-section" ref={triggerRef}>
            <div className="sbr-section-header" ref={headerRef}>
                <HighlightText text={t('services.header')} startWordIndex={2} endWordIndex={2} />
            </div>
            <div className="services-caption" ref={captionRef}>
                {t('services.description')}
            </div>
            <ul className="services-list" ref={listRef}>
                {renderedServicesContent}
            </ul>
        </section>
    );
};

export default Services;