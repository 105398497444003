import './style/loader.scss';

const Placeholder = () => {

    return (
        <div class="loading">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    );
};

export default Placeholder;