import { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";

const PopupForm = ({ path }) => {

    const { t, i18n } = useTranslation();

    const [showPopup, setShowPopup] = useState(true);
    
    const storage = getStorage();
    const [nameValue, setNameValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');



    /*   Handle download presentation */
    const handleDownload = async (url) => {
        try {
            const storageRef = ref(storage);
            const fileRef = ref(storageRef, `presentations/${path}.pdf`);

            const url = await getDownloadURL(fileRef);
            const a = document.createElement('a');
            a.href = url;
            a.download = `presentations/${path}.pdf`;
            // a.target = '_blank';
            a.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    /* Popup checking */
    const getCatalog = (event) => {
        event.preventDefault();
        if (nameValue.trim() !== "" && phoneValue.trim() !== "") {
            localStorage.setItem("userSentInfo", "true");
            handleDownload();
        } else {
            return
        }
    };

    useEffect(() => {

        const userSentInfo = localStorage.getItem('userSentInfo');
    
        if (userSentInfo) {
          setShowPopup(false);
          handleDownload();
        }
      }, []);

    return (

        <>
        {showPopup && (
        <div className="popup-body">
        <div className="popup-title">
        <HighlightText text={ t('pop-up.title') } startWordIndex={3} endWordIndex={7}/>
            </div>
        <div className='contact-form-wrap'>
            <form>
                <div className="col-md-6">
                    <div className="form-grp">
                        <input id="name" style={{ WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none' }} type="text" name="from_name" value={nameValue} onChange={(e) => setNameValue(e.target.value)} placeholder={ t('pop-up.name') } required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-grp">
                        <input
                            id="phone"
                            type="phone"
                            name="from_phone"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            value={phoneValue}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value.replace(/\D/g, '');
                                setPhoneValue(sanitizedValue);
                            }}
                            placeholder={ t('pop-up.phone') } />
                    </div>
                </div>
                <div className="submit-btn text-center" style={{ paddingTop: '20px' }}>
                    <button type="submit" className="sbr-btn_cta" onClick={(event) => getCatalog(event)}>
                    { t('pop-up.send-button') }
                    </button>
                </div>
            </form>
        </div>
    </div>
        )}
        </>

    );
}

export default PopupForm