import { useEffect, useRef } from "react";
import { magicShow, magicItemShow } from "./transition";
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";
import { Link } from "react-router-dom";

const Partners = ({ partnersData }) => {

    const { t } = useTranslation();

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];

    const combinedItemRefs = (el) => {
        if (el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        magicItemShow(itemRef.current);
        magicShow(triggerRef.current, [headerRef.current]);
    }, []);

    var list = t('partners.list', { returnObjects: true })
    const renderedPartnersContent = list.map((item, index) => {

        return (
            <li ref={combinedItemRefs} key={index}>
            <div className="icon">
                <img src={require(`../../assets/image/icons/partners/${item.icon}.svg`)} alt={item.text} />
            </div>
            <div className="title">{item.text}</div>
            <Link className="button" to="/partners"><span>{ t('buttons.more') }</span></Link>
        </li>
        );
    });

    return (
        <section className="sbr-section" ref={triggerRef}>
            <div className="sbr-section-header" ref={headerRef}>
            <HighlightText text={ t('partners.header') } startWordIndex={2} endWordIndex={2}/>
            </div>
            <ul className="partners-list">
                {renderedPartnersContent}
            </ul>
        </section>
    );
};

export default Partners;