import React from 'react'
import Layout from '../components/layout/Layout'
import { useTranslation } from 'react-i18next';
import HighlightText from '../components/highlitedText'

export default function PremiumLocations() {

  const { t, i18n } = useTranslation();

  var listData = t('premium-locations.locations', { returnObjects: true });

  const renderedItems = listData.map((item, index) => (
    <div className="grid-item">
      <h2>{item.title}</h2>
      <img src={require(`../assets/image/navigation-block/premium-locations/${item.imageSrc}.jpg`)} alt="" />
      <div className="buttons">
        <a href="">{t('premium-locations.downloadButton')}</a>
        <a href="">{t('premium-locations.moreButton')}</a>
      </div>
    </div>
  ))

  return (
    <Layout>
      <section id="investment-packages" className="sbr-section" style={{ paddingTop: '100px' }}>
        <div className="sbr-section-header" style={{ maxWidth: '100%' }}>
          <HighlightText text={t('premium-locations.header')} startWordIndex={2} endWordIndex={2} />
        </div>
        <div className="premium-locations-grid">
          {renderedItems}
        </div>
      </section>
    </Layout>
  )
}