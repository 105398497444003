const ServiceItem = ({
    item,
    index,
}) => (
    <li>
        <div className="service-item-icon">
            <img src={require(`../../assets/image/icons/services/${item.icon}.svg`)} alt={index} />
        </div>
        <div className="service-item-text">{item.text}</div>
    </li>
);

export default ServiceItem;