import { useEffect, useRef, useState } from "react";
import { magicShow } from "./transition";
import Popup from "../../components/popup/Popup";
import PopupForm from "../../components/popup/PopupForm";
import { useTranslation } from 'react-i18next';
import HighlightText from "../../components/highlitedText";

const GetPresentation = () => {

  const { t, i18n } = useTranslation();

  const [popUpShow, setPopUpShow] = useState(false);
  const [filePath, setFilePath] = useState(false);

  /* Animation effects */
  const triggerRef = useRef();
  const headerRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    magicShow(
      triggerRef.current,
      [headerRef.current, contentRef.current]
    );
  }, []);

  /* Popup configuration */
  const popUpConfig = (path) => {
    setFilePath(path);
    setPopUpShow(true);
  };

  return (
    <>
      <section className="sbr-section" ref={triggerRef}>
        <div className="get-presentation-wrapper">
          <div className="sbr-section-header" ref={headerRef}>
          <HighlightText text={ t('get-presentation.header') } startWordIndex={3} endWordIndex={7}/>
          </div>
          <a className="get-presentation-button" onClick={() => popUpConfig("top-areas-dubai/top-areas-property-dubai")} target="_blank" ref={contentRef}>
            <span className="title">{ t('buttons.download-presentation') }</span>
            <span className="format">PDF</span>
          </a>
        </div>
      </section>
      <Popup trigger={popUpShow} setTrigger={setPopUpShow}>
        <PopupForm path={filePath} />
      </Popup>
    </>
  );
};

export default GetPresentation;